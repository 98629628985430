<template>
    <div class="idx-wrapper">
        <!--顶部工具栏-->
        <top-nav></top-nav>

        <!--logo及头部-->
        <header-guide></header-guide>

        <!--项目菜单-->
        <div class="task-menu">
            <div class="container">
                <ul>
                    <li>
                        <router-link to="/task/list?search_type=1&tag=测绘评估">测绘评估</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=勘察设计">勘察设计</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=工程造价">工程造价</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=工程监理">工程监理</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=财务会计">财务会计</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=税务法务">税务法务</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=试验检测">试验检测</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=技术承包">技术承包</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=装修设计">装修设计</router-link>
                    </li>

                    <li>
                        <router-link to="/task/list?search_type=1&tag=其他分类">其他分类</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <!--banner-->
        <div class="banner">
            <el-carousel
                style="height:420px;"
                :loop="true"
            >
                <el-carousel-item
                    v-for="(item,index) in banner_list"
                    style="height:420px;"
                    :key="index"
                >
                    <a :href="item.from_url">
                        <div
                            :style="{'background':'url('+websiteConfigs.sourceUrl+item.img+') no-repeat center center'}"
                            style="width:100%;height:440px;"
                        ></div>
                    </a>
                </el-carousel-item>
            </el-carousel>
        </div>

        <!--统计-->
        <div class="statistics">
            <div class="container">
                <ul class="list">
                    <li class="item">
                        <div class="num">{{statistics.task_count}}</div>
                        <div class="desc">
                            <div class="text">项目总数</div>
                        </div>
                    </li>

                    <li class="item">
                        <div class="num">
                            <span style="font-size:20px;margin-right:4px;">¥</span>
                            {{statistics.balance_count}}
                        </div>
                        <div class="desc">
                            <div class="text">项目金额</div>
                        </div>
                    </li>

                    <li class="item">
                        <div class="num">{{statistics.geek_count}}</div>
                        <div class="desc">
                            <div class="text">注册用户</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!--外包项目-->
        <div class="task">
            <div class="container">
                <div class="header-box">
                    <div class="left">
                        <h3 class="title">外包项目</h3>
                        <span class="desc">靠谱项目，交付有保障</span>
                    </div>
                    <div class="right more">
                        <a href="/task/list">查看更多</a>
                    </div>
                </div>

                <div class="bodyer">
                    <ul class="list">
                        <li class="item">
                            <div class="head">
                                <div class="left">
                                    <h4 class="title">最新项目</h4>
                                    <span class="icon new">NEW</span>
                                </div>

                                <div class="right">
                                    <a
                                        href="javascript:;"
                                        @click="$router.push('/task/list')"
                                    >
                                        <div class="button">
                                            <span class="text">查看更多</span>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="content">
                                <div class="sub-list">
                                    <div
                                        class="sub-item"
                                        v-for="(item,index) in new_task"
                                        :key="index"
                                    >
                                        <div class="cate">{{item.speciality_type_value}}</div>
                                        <h6
                                            class="title"
                                            @click="taskDetail(item.tk)"
                                        >{{item.task_name}}</h6>

                                        <div class="two">
                                            <span class="price">¥ {{item._price}}</span>
                                            <span class="type">{{item.task_type_value}} |</span>
                                            <span class="address">{{item._address}} |</span>
                                            <span class="enroll">{{item.enroll_count}}人报名</span>
                                            <span class="time">发布时间：{{item._create}}</span>
                                        </div>

                                        <div class="status">{{item.status_value}}</div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="item">
                            <div class="head">
                                <div class="left">
                                    <h4 class="title">最热项目</h4>
                                    <span class="icon hot">HOT</span>
                                </div>

                                <div class="right">
                                    <a
                                        href="javascript:;"
                                        @click="$router.push('/task/list')"
                                    >
                                        <div class="button">
                                            <span class="text">查看更多</span>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="content">
                                <div class="sub-list">
                                    <div
                                        class="sub-item"
                                        v-for="(item,index) in hot_task"
                                        :key="index"
                                    >
                                        <div class="cate">{{item.speciality_type_value}}</div>
                                        <h6
                                            class="title"
                                            @click="taskDetail(item.tk)"
                                        >{{item.task_name}}</h6>

                                        <div class="two">
                                            <span class="price">¥ {{item._price}}</span>
                                            <span class="type">{{item.task_type_value}} |</span>
                                            <span class="address">{{item._address}} |</span>
                                            <span class="enroll">{{item.enroll_count}}人报名</span>
                                            <span class="time">发布时间：{{item._create}}</span>
                                        </div>

                                        <div class="status">{{item.status_value}}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--工程极客-->
        <div class="geek-list">
            <div class="container">
                <div class="header-box">
                    <div class="left">
                        <h3 class="title">工程极客</h3>
                        <span class="desc">人才荟萃，快速响应</span>
                    </div>
                    <div class="right">
                        <a href="/geek/list">查看更多</a>
                    </div>
                </div>

                <div class="bodyer">
                    <ul class="list">
                        <li
                            class="item"
                            v-for="(geek,index) in geek_list"
                            :key="index"
                            :style="{background:geek.attestation_type==1?'#fff url('+$img.geek_bg_gr+') no-repeat right top':geek.attestation_type==2?'#fff url('+$img.geek_bg_gzs+') no-repeat right top':'#fff url('+$img.geek_bg_dw+') no-repeat right top'}"
                        >

                            <router-link :to="'/firm/index?uk='+geek.uk">
                                <div class="avatar">
                                    <el-image :src="util.reImg(geek.avatar)"></el-image>
                                </div>
                            </router-link>
                            <div class="name">{{geek.name}}</div>
                            <div class="type-area">
                                <div class="type">{{geek.ability_tags}}</div>
                                <div class="area">
                                    <el-image
                                        class="icon"
                                        :src="$img.index_address_icon"
                                    ></el-image>
                                    <span class="text">{{geek.area_value}}</span>
                                </div>
                            </div>
                            <div class="rate">
                                <el-rate
                                    :value="parseFloat(geek.credence)"
                                    disabled
                                    disabled-void-color="#ebebeb"
                                    show-score
                                    text-color="#999"
                                    score-template="{value}"
                                ></el-rate>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--服务范围-->
        <div class="services">
            <div class="container">
                <div class="header-box">
                    <div class="left">
                        <h3 class="title">服务范围</h3>
                        <span class="desc">工程咨询，专业众包</span>
                    </div>
                    <div class="right">
                        <router-link to="/task/list">查看更多</router-link>
                    </div>
                </div>

                <div class="bodyer">
                    <ul class="list">
                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=测绘评估">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_1"></el-image>
                                </div>
                                <div class="text">测绘评估</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=勘察设计">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_2"></el-image>
                                </div>
                                <div class="text">勘察设计</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=工程造价">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_3"></el-image>
                                </div>
                                <div class="text">工程造价</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=工程监理">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_4"></el-image>
                                </div>
                                <div class="text">工程监理</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=财务会计">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_5"></el-image>
                                </div>
                                <div class="text">财务会计</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=税务法务">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_6"></el-image>
                                </div>
                                <div class="text">税务法务</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=试验检测">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_7"></el-image>
                                </div>
                                <div class="text">试验检测</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=技术承包">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_8"></el-image>
                                </div>
                                <div class="text">技术承包</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=装修设计">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_9"></el-image>
                                </div>
                                <div class="text">装修设计</div>
                            </router-link>
                        </li>

                        <li class="item">
                            <router-link to="/task/list?search_type=1&tag=其他分类">
                                <div class="icon">
                                    <el-image :src="$img.server_icon_10"></el-image>
                                </div>
                                <div class="text">其他分类</div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--优势-->
        <div class="advantage">
            <div class="container">
                <div class="header-box">
                    <div class="left">
                        <h3 class="title">平台优势</h3>
                        <span class="desc">靠谱项目，交付有保障</span>
                    </div>
                </div>

                <div class="bodyer">
                    <ul class="list">
                        <li class="item">
                            <el-image
                                class="icon"
                                :src="$img.index_ys_icon1"
                            ></el-image>
                            <div class="title">放心托管</div>
                            <div class="desc">雇主可以自由选择是否托管酬金，托管后的酬金由平台进行担保，资金零风险，安全有保障。</div>
                        </li>

                        <li class="item">
                            <el-image
                                class="icon"
                                :src="$img.index_ys_icon2"
                            ></el-image>
                            <div class="title">电子合同</div>
                            <div class="desc">雇主和极客自由选择是否在线签署电子合同、实名认证、第三方证据托管。电子合同与纸质合同具有同等法律效力，保护雇主与工程极客的权益。</div>
                        </li>

                        <li class="item">
                            <el-image
                                class="icon"
                                :src="$img.index_ys_icon3"
                            ></el-image>
                            <div class="title">分段支付</div>
                            <div class="desc">雇主可以自由设定支付里程碑，分阶段支付极客酬金。只有雇主对项目成果满意时，才会支付托管的酬金。</div>
                        </li>

                        <li class="item">
                            <el-image
                                class="icon"
                                :src="$img.index_ys_icon4"
                            ></el-image>
                            <div class="title">档案管理</div>
                            <div class="desc">所有操作均有记录及档案，可查阅并打印。</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--类型-->
        <div class="user_type">
            <div class="container">
                <div class="header-box">
                    <div class="left">
                        <h3 class="title">类型说明</h3>
                        <span class="desc">靠谱项目，交付有保障</span>
                    </div>
                </div>

                <div class="bodyer">
                    <ul class="list">
                        <li class="item">
                            <div class="left left-bg">
                                <div class="title">项目类型</div>
                                <div class="line blue"></div>
                            </div>
                            <div class="right">
                                <ul class="sub-list">
                                    <li class="sub-item">
                                        <el-image
                                            class="icon"
                                            :src="$img.index_type_icon1"
                                        ></el-image>
                                        <div class="title">竞价项目</div>
                                        <div class="desc">雇主设定酬金上下限，工程极客报名时进行报价，雇主在价格优先的情况下选定工程极客。</div>
                                    </li>

                                    <li class="sub-item">
                                        <el-image
                                            class="icon"
                                            :src="$img.index_type_icon2"
                                        ></el-image>
                                        <div class="title">比选项目</div>
                                        <div class="desc">雇主设定具体酬金，工程极客进行报名，雇主择优选定工程极客。</div>
                                    </li>

                                    <li class="sub-item">
                                        <el-image
                                            class="icon"
                                            :src="$img.index_type_icon3"
                                        ></el-image>
                                        <div class="title">日薪项目</div>
                                        <div class="desc">雇主设定每日酬金及工作天数，工程极客进行报名，雇主择优选定工程极客。</div>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li class="item">
                            <div class="left right-bg">
                                <div class="title">极客类型</div>
                                <div class="line orange"></div>
                            </div>
                            <div class="right">
                                <ul class="sub-list">
                                    <li class="sub-item">
                                        <el-image
                                            class="icon"
                                            :src="$img.index_type_icon4"
                                        ></el-image>
                                        <div class="title">企业工程极客</div>
                                        <div class="desc">具有营业执照和专业人员的企业单位。</div>
                                    </li>

                                    <li class="sub-item">
                                        <el-image
                                            class="icon"
                                            :src="$img.index_type_icon5"
                                        ></el-image>
                                        <div class="title">工作室工程极客</div>
                                        <div class="desc">由两个以上专业人员组成的工作室。</div>
                                    </li>

                                    <li class="sub-item">
                                        <el-image
                                            class="icon"
                                            :src="$img.index_type_icon6"
                                        ></el-image>
                                        <div class="title">个人工程极客</div>
                                        <div class="desc">具有专业能力的个人</div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--雇主及极客流程-->
        <div
            class="geek_employer"
            id="liucheng"
        >
            <div class="container">
                <div class="header-box">
                    <div class="left">
                        <h3 class="title">雇主及极客流程</h3>
                        <span class="desc">高效、全面的服务保障</span>
                    </div>
                </div>

                <div class="bodyer">
                    <div class="head">
                        <div class="title blue">
                            <el-image
                                class="icon"
                                :src="$img.index_employer_icon"
                            ></el-image>
                            <div class="text">雇主流程</div>
                        </div>

                        <div class="title orange">
                            <el-image
                                class="icon"
                                :src="$img.index_geek_icon"
                            ></el-image>
                            <div class="text">极客流程</div>
                        </div>
                    </div>

                    <div class="content">
                        <div class="list llist">
                            <div class="item">
                                <div class="title">雇主发布项目</div>
                                <div class="desc">注册成为雇主用户，并通过实名认证后，即可发布项目。</div>
                            </div>

                            <div class="item">
                                <div class="title">雇主发出邀请</div>
                                <div class="desc">雇主向已报名人发送站内信洽谈，择优邀请一名极客，邀请受到确认，不能重发；邀请受到拒绝或在12小时内没有回应，可再发邀请。</div>
                            </div>

                            <div class="item">
                                <div class="title">雇主托管酬金</div>
                                <div class="desc">受邀工程极客接受项目后，雇主在24小时内托管酬金。酬金为邀请函中金额。托管酬金后可选择加签电子合同。</div>
                            </div>

                            <div class="item">
                                <div class="title">开始执行项目</div>
                                <div class="desc">工程极客开始为雇主完成项目</div>
                            </div>

                            <div class="item">
                                <div class="title">雇主支付酬金</div>
                                <div class="desc">工程极客提交成果后，雇主查看成果，并在线确认支付酬金。</div>
                            </div>
                            <div class="item">
                                <div class="title">双方互评</div>
                                <div class="desc">评价双方的表现</div>
                            </div>
                        </div>

                        <div class="center">
                            <ul class="icon-list">
                                <li class="icon-item">
                                    <div class="num">01</div>
                                    <div class="icon"></div>
                                </li>

                                <li class="icon-item">
                                    <div class="num">02</div>
                                    <div class="icon"></div>
                                </li>

                                <li class="icon-item">
                                    <div class="num">03</div>
                                    <div class="icon"></div>
                                </li>

                                <li class="icon-item">
                                    <div class="num">04</div>
                                    <div class="icon"></div>
                                </li>

                                <li class="icon-item">
                                    <div class="num">05</div>
                                    <div class="icon"></div>
                                </li>

                                <li class="icon-item">
                                    <div class="num">06</div>
                                </li>
                            </ul>
                        </div>

                        <div class="list rlist">
                            <div class="item">
                                <div class="title">报名参加项目</div>
                                <div class="desc">注册成极客用户，并完成入驻流程，即可报名项目</div>
                            </div>

                            <div class="item">
                                <div class="title">极客确认邀请</div>
                                <div class="desc">工程极客查看邀请，可选择接受或拒绝邀请</div>
                            </div>

                            <div class="item">
                                <div class="title">极客托管履约保证金</div>
                                <div class="desc">如雇主在邀请中约定了极客需要缴纳履约保证金，则极客在接受项目的24小时内托管履约保证金。托管酬金后可选择加签电子合同</div>
                            </div>

                            <div class="item">
                                <div class="title">开始执行项目</div>
                                <div class="desc">工程极客开始为雇主完成项目</div>
                            </div>

                            <div class="item">
                                <div class="title">完成委托项目</div>
                                <div class="desc">工程极客在约定时间内提交成果，经雇主确认并支付后，收取酬金并获得履约保障金退款。</div>
                            </div>
                            <div class="item">
                                <div class="title">双方互评</div>
                                <div class="desc">评价双方的表现</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--合作单位-->
        <div class="parnter">
            <div class="container">
                <div class="header-box">
                    <div class="left">
                        <h3 class="title">合作单位</h3>
                        <span class="desc">多家企业合同，放心选择</span>
                    </div>
                </div>

                <div class="bodyer">
                    <ul class="list">
                        <li
                            class="item"
                            v-for="(item,index) in parnter_list"
                            :key="index"
                        >
                            <router-link :to="item.from_url">
                                <el-image :src="util.reImg(item.img)"></el-image>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--底部-->
        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "index",
    metaInfo: {
        title: "询龙网 - 工程领域专业服务众包平台",
        meta: [
            {
                name: "keywords",
                content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
            }, {
                name: "description",
                content: "询龙网，工程领域专业服务众包平台，是一家工程技术咨询服务+互联网众包的平台，为行业内企业及个人提供线上零距离招投标服务。建设单位线上发布工程技术咨询招标信息，通过竞价或比选模式，择优选取中标单位。建设单位或咨询企业组织评审会议、完成短期任务，通过发布日薪任务快速选择业内专家或工程师。企业或个人根据需求线上选择优秀的技术咨询人才或团队，再也不用担心项目多无人才、项目少养闲人的尴尬局面。技术咨询企业和工作室在线上寻找优质项目，赚取咨询费。技术人才实现职业自由，无需为通勤交通烦恼，在家上班赚取酬金。建设工程行业资料分享。线上可以选择签署具有法律效力的电子合同及托管酬金。询龙网，工程技术咨询众包平台。雇主线上免费发布咨询项目，企业或个人线上免费报名投标，双方达成意愿，可线上签署电子合同托管酬金。平台涵盖测绘评估、勘察设计、监理造价、会计税务、法务、检测、资料及工程技术等各项外包服务。"
            }
        ]
    },
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        GlobalFooter //公共底部
    },
    data() {
        return {
            statistics: [], //统计数据
            hot_task: [], //热门项目
            new_task: [], //最新项目
            new_task_count: 0, //最新项目
            hot_task_count: 0, //最热项目
            geek_list: [], //极客列表
            parnter_list: [], //合作单位列表
            banner_list: [],
        };
    },
    methods: {
        //加载统计数据
        loadStatisticsData() {
            let _this = this;
            this.post("/task/index/index_data", {}, function (res) {
                if (res.code == 200) {
                    _this.statistics = res.data;
                }
            });
        },

        //加载最新项目
        loadNewTaskData() {
            let _this = this;
            this.post(
                "/task/index/task_list",
                {
                    page: 1,
                    limit: 5
                },
                function (res) {
                    if (res.code == 200) {
                        _this.new_task = res.data.list;
                        _this.new_task_count = res.data.count;
                    }
                }
            );
        },

        getQueryString(name) {
            var winObj = arguments.length > 1 ? arguments[1] : window;
            var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
            if (typeof (winObj.location) != "object") { winObj = window; }
            if (reg.test(winObj.location.href)) return unescape(RegExp.$2.replace(/\+/g, " ")); return "";
        },

        //加载热门项目
        loadHotTaskData() {
            let _this = this;
            this.post(
                "/task/index/task_list",
                {
                    type: "hot",
                    page: 1,
                    limit: 5
                },
                function (res) {
                    if (res.code == 200) {
                        _this.hot_task = res.data.list;
                        _this.hot_task_count = res.data.count;
                    }
                }
            );
        },

        //加载极客列表
        loadGeekData() {
            let _this = this;
            this.post(
                "/user/index/geek_list",
                {
                    page: 1,
                    limit: 5
                },
                function (res) {
                    if (res.code == 200) {
                        _this.geek_list = res.data;
                    }
                }
            );
        },

        //加载合作单位数据
        loadParnterData() {
            let _this = this;
            this.post(
                "/article/index/lists",
                {
                    profession: 2,
                    page: 1,
                    limit: 5
                },
                function (res) {
                    if (res.code == 200) {
                        _this.parnter_list = res.data.list;
                    }
                }
            );
        },

        //banner数据
        loadBannerData() {
            let _this = this;
            this.post(
                "/article/index/lists",
                {
                    profession: 31,
                    page: 1,
                    limit: 10
                },
                function (res) {
                    if (res.code == 200) {
                        _this.banner_list = res.data.list;
                    }
                }
            );
        },

        //跳转到项目详细页
        taskDetail(tk) {
            this.$router.push("/task/detail?tk=" + tk);
        }
    },
    created() {
        this.loadStatisticsData(); //加载统计数据
        this.loadNewTaskData();
        this.loadHotTaskData();
        this.loadGeekData();
        this.loadParnterData();
        this.loadBannerData();//加载banner
    }
};
</script>

<style lang="less" scoped>
@import "../../styles/index.less";
</style>




